import React, {Component} from "react";
import {Container, Typography} from "@material-ui/core";
import CodeHello from "./CodeHello";
import Typewriter from "typewriter-effect";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({

    image: {
        minWidth: "50px",
        minHeight: "50px",
        maxHeight: "50px",
        maxWidth: "50px",
        [theme.breakpoints.down("xs")]: {
            minWidth: "35px",
            minHeight: "35px",
            maxHeight: "35px",
            maxWidth: "35px",
        },
    },
    typing: {
        [theme.breakpoints.down("xs")]: {
            fontSize: "1rem"
        }
    }
});

class HelloWorld extends Component {

    constructor(props) {
        super(props);

        this.state = {
            index: 0,
            showLogo: false
        };
    }

    componentDidMount() {
        this.getNextHelloWorld();
    }

    componentWillUnmount() {
        this.typewriter.stop();
    }

    getNextHelloWorld = () => {

        let nextIndex = Math.floor(Math.random() * CodeHello.length - 1) + 1;
        while (nextIndex == this.state.index) {
            nextIndex = Math.floor(Math.random() * CodeHello.length - 1) + 1;
        }

        this.setState({index: nextIndex});
    };

    async typeCode(typewriter) {

        this.typewriter = typewriter;
        this.typewriter.start();

        while (true) {
            await this.typeCodePromise(this.typewriter);
            this.getNextHelloWorld();
        }
    }

    typeCodePromise = (type_writer) => {
        return new Promise(resolve => {
            type_writer
                .typeString(CodeHello[this.state.index].code)
                .pauseFor(1500)
                .deleteAll()
                .callFunction(() => resolve(type_writer), type_writer)
        });
    };

    render() {
        const {classes} = this.props;
        return (
            <Container maxWidth="md" style={{paddingTop: "32px"}}>
                <Typography variant="h4" style={{textAlign: "center"}}>
                    Developing in
                </Typography>
                <hr/>


                <div style={{marginRight: "16px", marginTop: "8px", float: "left"}}>
                    <img src={CodeHello[this.state.index].icon} alt="" className={classes.image}/>
                </div>

                <Typography variant="h5" style={{marginTop: "24px"}} className={classes.typing}>
                    <Typewriter
                        onInit={(typewriter) => this.typeCode(typewriter)}/>
                </Typography>
            </Container>
        )
    }
}

export default withStyles(styles)(HelloWorld);