import React from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Avatar from "@material-ui/core/Avatar"
import ImageIcon from "@material-ui/icons/Image"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import AppBar from "@material-ui/core/AppBar"
import {Toolbar} from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCode} from "@fortawesome/free-solid-svg-icons"


export default function DevTwitter() {
    return (
        <Container style={{paddingTop: "32px"}}>
            <AppBar position="relative" style={{background: "#009688"}}>
                <Toolbar>
                    <FontAwesomeIcon icon={faCode} size="2x" style={{paddingRight: "16px"}}/>
                    <Typography variant="h5">
                        Dev Updates
                    </Typography>
                </Toolbar>
            </AppBar>
            <List style={{maxHeight: "calc(100vh - 280px)", overflow: "auto"}}>
                {[...Array(50).keys()].map((a) => { // TODO ARRAY CALL
                    return <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <ImageIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Photos" secondary={a}/>
                    </ListItem>
                })}
            </List>
        </Container>
    )
}