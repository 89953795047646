import React from "react"
import Layout from "../components/Layout/Layout"
import {makeStyles} from "@material-ui/core"
import HelloWorld from "../components/Index/HelloWorld/HelloWorld";
import About from "../components/Index/About/About";
import DevTwitter from "../components/Index/DevTwitter/DevTwitter";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        height: "calc(100% - 128px)",
        display: "grid",
        gridTemplateColumns: "8fr 3fr",
        gridTemplateRows: "2fr 8fr",
        [theme.breakpoints.down("md")]: {
            gridTemplateColumns: "1fr",
            gridTemplateRows: "2fr 8fr",
        }
    },
    devTwitter: {
        gridArea: "1/2 / 3/2",
        [theme.breakpoints.down("md")]: {
            display: "none"
        }
    }

}));

export default function () {

    const classes = useStyles();

    return (

        <Layout>
            <div className={classes.root}>
                <div>
                    <HelloWorld/>
                </div>
                <div>
                     {/*<About/>*/}
                </div>
                <div className={classes.devTwitter}>
                    <DevTwitter/>
                </div>
            </div>
        </Layout>
    )
}
