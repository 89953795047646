import C_LOGO from "../../../data/hello_world/c.svg"
import DOCKER_LOGO from "../../../data/hello_world/docker.svg"
import LINUX_LOGO from "../../../data/hello_world/linux.svg"
import GIT_LOGO from "../../../data/hello_world/git-icon.svg"
import KOTLIN_LOGO from "../../../data/hello_world/kotlin.svg"
import NODE_LOGO from "../../../data/hello_world/node.svg"
import PYTHON_LOGO from "../../../data/hello_world/python.svg"
import REACT_LOGO from "../../../data/hello_world/react.svg"
import JAVA_LOGO from "../../../data/hello_world/java.svg"

export default [
    {
        icon: "",
        code: ""
    },
    {
        icon: LINUX_LOGO,
        code: "echo \"Hello World\""
    },
    {
        icon: C_LOGO,
        code: "printf(\"Hello World\");"
    }, {
        icon: DOCKER_LOGO,
        code: "docker run hello-world"
    },
    {
        icon: GIT_LOGO,
        code: "git commit -m \"Hello World\""
    },
    {
        icon: KOTLIN_LOGO,
        code: "println(\"Hello World!\")"
    },
    {
        icon: PYTHON_LOGO,
        code: "print(\"Hello World\")"
    }, {
        icon: NODE_LOGO,
        code: "console.log(\"Hello World\");"
    },
    {
        icon: REACT_LOGO,
        code: ["<", "p", ">", "Hello World", "<", "\/", "p", ">"].join("\u{200B}")
    },
    {
        icon: JAVA_LOGO,
        code: "System.out.println(\"Hello World\");"
    }
];